import type {
    CashRegisterConfig,
    CommonConfig,
    DepartmentConfig,
    DeviceConfig,
    MerchantConfig,
    MerchantDepartmentConfig,
} from "./types";

// COMMON
export const commonConfigDefault: Readonly<CommonConfig> = {
    welcomeMessage: "",
    printOrderWhenCompleted: false,
    logOutWhenCompleted: "no",
    language: "en",
    miscButtons: null,
    buttonLayout: null,
    paymentOptions: null,
    tableLayout: null,
    rotation: "0",
    autoDeleteOrderIdleTimeout: 0,
    autoDeleteOrderWarningTimeout: 0,
    warnOnRefundAndReturn: true,
    queryCustomersForData: false,
    enableLayoutSwipe: false,
};

// MERCHANT & DEPARTMENT
export const merchantDepartmentConfig: Readonly<MerchantDepartmentConfig> = {
    address: "",
    zipcode: "",
    city: "",
    country: "",
    email: "",
    phone: "",
    cvr: "",
    headerText: "",
    footerText: "",
    showServedBy: false,
    sumupAffiliateKey: "",
    sumupClientId: "",
    sumupClientSecret: "",
    sumupRefreshToken: "",
    sumupRedirectUrl: "",
    softpayIntegratorId: "",
    softpayMerchantReference: "",
};

// MERCHANT
export const merchantConfigDefault: Readonly<MerchantConfig> = {
    ...commonConfigDefault,
    ...merchantDepartmentConfig,
    currency: "DKK",
    defaultVat: null,
    defaultDepartment: null,
    giftcardVatBookkeepingMethod: "AT_ISSUE",
    barcodeOnReceipt: true,
    showServedBy: false,
};

// DEPARTMENT
export const departmentConfigDefault: Readonly<DepartmentConfig> = {
    ...commonConfigDefault,
    ...merchantDepartmentConfig,
};

// CASH REGISTER
export const cashRegisterConfigDefault: Readonly<CashRegisterConfig> = {
    receiptPrinter: null,
    ticketPrinter: null,
    showServedBy: false,
    softpayCloudTerminalAppId: null,
    ...commonConfigDefault,
};

// DEVICE
export const deviceConfigDefault: Readonly<DeviceConfig> = {
    ...merchantConfigDefault,
    ...departmentConfigDefault,
    ...cashRegisterConfigDefault,
};
