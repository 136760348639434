import { t } from "i18next";
import {
    baseButtonColor,
    GridDimensions,
    ILayoutProduct,
    IProductGroup,
    ProductButton,
    ProductSection,
} from "lib";
import React, { createContext, useReducer } from "react";
import {
    ProductLayoutActionType,
    productLayoutReducer,
    ProductLayoutReducerState,
} from "./edit/product-layout-operation-reducer";
import { getMaxDimensionsForCoordinate } from "./functions";
import { LayoutActionType } from "./layout-operation-reducer";

export const LayoutButtonsContext =
    createContext<ProductLayoutReducerState | null>(null);
export const LayoutDispatchContext = createContext<React.Dispatch<
    LayoutActionType | ProductLayoutActionType
> | null>(null);

export function ProductLayoutProvider({
    sections,
    knownProducts,
    knownProductGroups,
    gridDimensions,
    onLayoutUpdate,
    children,
}: {
    sections: ProductSection[];
    knownProducts: ILayoutProduct[];
    knownProductGroups: Pick<IProductGroup, "id" | "color">[];
    gridDimensions: GridDimensions;
    onLayoutUpdate: (value: any) => void;
    children: React.ReactNode;
}) {
    const [layoutState, dispatch] = useReducer(
        productLayoutReducer,
        {
            sections,
            knownProducts,
            knownProductGroups,
            dimensions: gridDimensions,
            currentSectionIndex: 0,
            buttonMetaData: {},
            onLayoutUpdate,
        },
        createInitialState
    );

    return (
        <LayoutButtonsContext.Provider value={layoutState}>
            <LayoutDispatchContext.Provider value={dispatch}>
                {children}
            </LayoutDispatchContext.Provider>
        </LayoutButtonsContext.Provider>
    );
}

function createInitialState({
    sections,
    knownProducts,
    knownProductGroups,
    dimensions,
    currentSectionIndex,
    buttonMetaData,
    onLayoutUpdate,
}: ProductLayoutReducerState) {
    let returnValue: ProductLayoutReducerState = {
        sections,
        knownProducts,
        knownProductGroups,
        dimensions,
        currentSectionIndex,
        buttonMetaData,
        onLayoutUpdate,
    };

    // Check the buttons of all sections for product and product group references
    returnValue.sections = returnValue.sections.map(section => {
        // loop the buttons of this section to find product and product group references, update
        section.buttons = section.buttons
            .map(button => {
                if (!button) {
                    return;
                }

                if (button.buttonType === "PRODUCT") {
                    const buttonProduct = knownProducts.find(
                        productItr =>
                            productItr.id ===
                            (button as ProductButton).productId
                    );
                    if (!buttonProduct) {
                        // Product not found for id. Back off. Button will not be shown in layout.
                        return;
                    }

                    // Get product data and update metadata for button
                    returnValue.buttonMetaData[button.id] = {
                        color: !button.color
                            ? !buttonProduct.group?.color
                                ? baseButtonColor
                                : buttonProduct.group?.color
                            : button.color,
                        text: !button.label
                            ? !buttonProduct.buttonText
                                ? !buttonProduct.name
                                    ? t("backoffice.layout.no_text", "No text")
                                    : buttonProduct.name
                                : buttonProduct.buttonText
                            : button.label,
                    };

                    // Set the product amount on the button
                    return { ...button, amount: buttonProduct.amount };
                } else if (button.buttonType === "FUNCTION") {
                    returnValue.buttonMetaData[button.id] = {
                        ...returnValue.buttonMetaData[button.id],

                        text:
                            button.label ||
                            t("backoffice.layout.no_text", "No text"),
                    };

                    if (
                        button.function === "CUSTOM_ITEM" ||
                        button.function === "VENUE_ACCESS_TICKETS"
                    ) {
                        const foundGroup = knownProductGroups.find(
                            groupItr => groupItr.id === button.productGroupId
                        );

                        if (foundGroup) {
                            returnValue.buttonMetaData[button.id] = {
                                ...returnValue.buttonMetaData[button.id],

                                color: !button.color
                                    ? !foundGroup.color
                                        ? baseButtonColor
                                        : foundGroup.color
                                    : button.color,
                                text: button.label,
                            };
                        } else {
                            returnValue.buttonMetaData[button.id] = {
                                ...returnValue.buttonMetaData[button.id],

                                color: !button.color
                                    ? baseButtonColor
                                    : button.color,
                                text: button.label,
                            };
                        }
                    } else if (button.function === "BUY_ACCOUNT_FUNDS") {
                        returnValue.buttonMetaData[button.id] = {
                            ...returnValue.buttonMetaData[button.id],

                            color: !button.color
                                ? baseButtonColor
                                : button.color,
                        };
                    }
                } else {
                    // This is a button of unknown type. Back off. The button will not be shown in layout.
                    return;
                }

                // find the dimension bounds for the button
                const { width: maxWidth, height: maxHeight } =
                    getMaxDimensionsForCoordinate(
                        {
                            x: button.x,
                            y: button.y,
                        },
                        dimensions,
                        returnValue.sections[returnValue.currentSectionIndex]
                            ?.buttons,
                        []
                    );

                returnValue.buttonMetaData[button.id] = {
                    ...returnValue.buttonMetaData[button.id],

                    maxWidthValue: maxWidth,
                    maxHeightValue: maxHeight,
                };

                return button;
            })
            // Remove buttons with a "dead" product reference
            .filter(Boolean);

        return section;
    });

    return returnValue;
}
